.gallery_bx2{
    background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
    background: linear-gradient(180deg, #4e4d4d 0%, rgba(217, 217, 217, 0.00) 100%);
    background: linear-gradient(180deg,rgba(217, 217, 217, 0.00)  -22%, #504646 100%);
    min-height: 100vh;
   
}
.gallery_bx{
    padding: 40px;
}
.listing-small-image-box{
    display: flex;
    /* justify-content: space-around; */
    overflow-y: auto;
    gap: 10px;
    display: flex;
    justify-content: center;
    margin-top: 26px;
}
.listing-small-image-box img{
    height: 78px;
    width: 52px;
    /* height: 78px;
    width: 58px; */
}
.pagination-container2{
    display: flex;
    /* justify-content: space-around; */
    justify-content: center;
    flex-direction: row;
    color: #FFF;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 75.6px; /* 756% */
letter-spacing: 0.1px;
}
.page-number{
    margin-left: 5px;
    margin-right: 10px;
}
/* .image-list-container-mob33{
    top: 40px;
    position: relative;
} */

@media only screen and (max-width: 600px) {
    .gallery_bx{
        background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
        min-height: 100vh;
        padding: 0;
        top: -2px;
        position: relative;
        /* height: 511px; */
    }
}