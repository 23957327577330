@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Inter:wght@300;500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Inter:wght@300;400;500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Inter:wght@400;500&family=Oswald:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Inter:wght@100;300;400;500&family=Oswald:wght@200;300;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* cursor: pointer; */
}

html, body {
  touch-action: manipulation;
}

.navbar-toggler{
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 0;
}

body::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar width */
}

/* For Firefox */
body {
  scrollbar-width: 0;
  /* Remove scrollbar width */
}

.header {
  display: flex;
  justify-content: center; /* Horizontally center the items */
  align-items: center; /* Vertically center the items */
}

/*Header styles*/
/* section{
  height: 100vh;
} */
/* .navbar-nav{
  left: -50px;
  position: relative;
} */
header {
  height: 110px;
  flex-shrink: 0;
  /* background: #1B1B19; */
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  /* Add position: relative to create stacking context */
  z-index: 1000;
  /* Set a higher z-index value */
}
header ul{
  margin-right: 300px;
}
.section-web{
  top: -110px;
  position: relative;
}

.form-inline2{
  left: -86px;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 20px;
  
}
/* div.player{
  top: ;
} */
div.brand{
  margin-left: 86px;
}

div.brand h1{
  color: #FFF;
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 27.6px; /* 76.667% */
letter-spacing: 3.45px;
}
span.brand{
  color: #BC9B5D;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 2.9px;
}
h4.text-white{
  top: 100px;
  position: relative;
}
.footer-content h6 {
  font-family: Oswald;
  font-size: 16px;
}
.box-content {
  top: 90px;
  position: relative;
}
.footer-content h4 {
  color: #FFF;
  font-family: Inter;
  font-size: 13.359px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
  /* 190.877% */
  letter-spacing: 0.75px;
}
.footer{
  height: 324px;
  bottom: 0;
  position: fixed;
}
.footer-text{
  top: 82px;
  position: relative;
  display: flex;
  justify-content: space-between;
}


.footer-text h4{
  color: #FFF;
font-family: Oswald;
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 120% */
}

.footer-text h5,a{
  color: #FFF;
font-family: Inter;
font-size: 13.359px;
font-style: normal;
font-weight: 400;
line-height: 25.5px; /* 190.877% */
letter-spacing: 0.75px;
}
.name{
  font-size: 13.359px;
}
.footer-second {
  top: 35px;
  left: 10px;
  position: relative;
}

.footer-second h5,h6 {
  color: #FFF;
  font-family: Inter;
  font-size: 13.359px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
  /* 190.877% */
  letter-spacing: 0.75px;
}

.navbar-collapse {
  /* background: #1B1B19; */
  font-size: 40px;
}

.test {
  margin: 30px;
}
/* .footer-web{
  top: 40px;
  position: relative;
} */

.text-content {
  max-width: 553px;
}

a.nav-link {
  color: #A0A09F;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 416.667% */
}

.footer-link {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  /* 120% */
  text-decoration: none;
}

a.footer-link:hover {
  color: white;
}

.web-h {
  visibility: hidden;
}

.first_svg{
  top: 17px;
    position: relative;
    left: 40px 
}

.custom-toggler .navbar-toggler-icon {
  color: white;
}

.navbar-toggler {
  color: white;
  top: 30px;
  position: relative;
  left: -15px;
}

#navbarSupportedContent {
  color: red;
}

/* styles.css */
.expand-icon {
  float: right;
  left: -40px;
  top: 22px;
  position: relative;
}

.expand-icon svg {
  width: 20px;
  height: 20px;
}

.expand-icon p {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 75.6px;
  /* 756% */
  letter-spacing: 0.1px;
  top: -30px;
  left: -8px;
  position: relative;
}

.main-image-container.lightbox-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.main-image-container.lightbox-open img {
  /* max-width: 297px;
  max-height: 441px; */
  /* width: 491px; */
}

.overlay-container {
  position: relative;
  display: inline-block;
}

.overlay-image {
  max-width: 100%;
  height: auto;
  display: block;
}
img.expanded{
  width: 800px;
}

.overlay-svg {
  position: absolute;
  top: 54%;
  left: 57%;
  right: 0;
  transform: translateY(-50%);
}

.overly-text {
  color: #FFF;
  /* text-align: center; */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 75.6px;
  /* 756% */
  letter-spacing: 0.1px;
  margin-top: 22px;
  margin-left: -5px;
}

.nav-item {
  /* margin: 0 40px; */
  margin: 0 30px;

}

/*End header styles*/
.image-list-web {
  display: none;
}

section {
  position: relative;
}

.player {
  width: 960px;
  height: 544px;
}

.expand.hidden {
  display: none;
}

/* .close-lightbox {
  display: flex;
  float: right;
 
} */

.close-lightbox {
  /* position: absolute;
  top: 0;
  right: 20px;
  padding: 10px;
  cursor: pointer; */
  position: absolute;
  top: 100px;
  right: 35%;
  padding: 10px;
  cursor: pointer;
}


.play-pause-btn {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
}

.play-pause-btn3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
}

.play-pause-btn2 {
  position: absolute;
  top: 38%;
  left: 45%;
  transform: translate(-50%, -50%);
  border: none;
  background: transparent;
}

.play-cover {
  width: 57px;
  height: 35px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.70);
}

.play-cover3 {
  width: 57px;
  height: 35px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.70);
}

.play-btn {

  top: 5px;
  position: relative;
}

.fa-pause {
  top: 5px;
  position: relative;
  color: white;
}

.banner-text {
  position: absolute;
  bottom: 200px;
  right: 250px;
  padding: 20px;
  /* Adjust padding as needed */
  color: #FFF;
  /* Set the text color */
}

.banner-text h2 {
  color: #FFF;
  font-family: Inter;
  font-size: 63px;
  font-style: normal;
  font-weight: 400;
  line-height: 75.6px;
  /* 120% */
  letter-spacing: 9.45px;
}

.banner-text h4 {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.6px;
  /* 120% */
  letter-spacing: 3.45px;
  left: 100px;
  top: -15px;
  position: relative;
}

.banner-text h5 {
  color: #BC9B5D;
  font-family: Inter;
  font-size: 25.262px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.9px;
  left: 50px;
  top: -20px;
  position: relative;
}

.about-page {
  background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
  top: -124px;
  position: relative;

}

.gallery-page2 {
  background: #252121;
  background: linear-gradient(360deg, #252121 0%, #161515 100%);
}

.gallery-page {
  /* background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0.00) 100%); */
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);
  background: linear-gradient(180deg, rgba(18, 235, 36, 0) 0%, #0c0a0a 100%);
  background: linear-gradient(360deg, rgba(18, 235, 36, 0) 0%, #201c1c 100%);
  background: linear-gradient(360deg, rgba(18, 235, 36, 0) 0%, #a09d9d 100%);
  background: linear-gradient(360deg, rgba(18, 235, 36, 0) 0%, #ffffff 100%);
  
  margin-bottom: 50px;
  /* top: -8px; */
  position: relative;
  /* height: 100vh; */
}

.about-page h1 {
  color: #BC9B5D;
text-align: center;
font-family: Inter;
font-size: 70px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 9px;
top: 50px;
position: relative;
}

.about-page p {
  color: #000;
  /* text-align: center; */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  width: 926px;
  /* width: 1057px; */
  height: 267px;
}

.about-content {
  top: 130px;
  position: relative;
  margin: 5px;

}

.about-video {
  top: 113px;
  position: relative;
  width: 931px;
  display: flex;
  justify-content: center;
}


/* Gallery page */
.image-list-container-mob {
  /* visibility: hidden; */
  display: none;
}

.main-box {
  background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
}

.gallery-banner {
  background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
  height: 852px;
  width: 100%;
  /* min-width: 100%; */
  flex-shrink: 0;
}
a.gallery-page-text{
  font-size: 400px;
}
.gallery-banner h1 {
  color: #FFF;
  text-align: center;
  font-family: Oswald;
  font-size: 130px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
  align-items: center;
  top: 25%;
  position: relative;
}

.title {
  color: #FFF;
  text-align: center;
  font-family: Oswald;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
}

img.gallery-image {
  width: 806.319px;
  height: 427.582px;
  flex-shrink: 0;

}

.center-image {
  width: 333px;
  height: auto;
  flex-shrink: 0;
}

.same-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 35px;
  overflow-x: scroll;
}

.same-row::-webkit-scrollbar {
  width: 0;
}

img.sm-image {
  min-width: 158px;
  height: 237px;
  flex-shrink: 0;
  margin: 8px;
}

.same-row-cards {
  display: flex;
  justify-content: space-around;
}

.card-text h4 {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
}

.card-text hr {
  border: 4px solid white;
  width: 379.047px;
}

.text-content h4,
h6 {
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1px;
}

.content-wrapper {
  border-left: 2px;
  /* Adjust border color and width as needed */
  border-right: 2px;
  /* Adjust border color and width as needed */
  margin: 0 20px;
  /* Add margin on both right and left sides */
  max-width: 1200px;
  /* Set a maximum width for the content */
  margin-left: auto;
  /* Align the content to the center */
  margin-right: auto;
  /* Align the content to the center */
}

/* End gallery page */

/* Portfolio page */
.portfolio-page {
  background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
  margin-top: 130px;
  height: 2554px;
  top: 150px;
  position: relative;
}

.portfolio-page h1 {
  /* color: #BC9B5D;
  text-align: center;
  font-family: Oswald;
  font-size: 130px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px; */
  color: #BC9B5D;
text-align: center;
font-family: Inter;
font-size: 70px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 9px;
}

.svg-container {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.portfolio-content {
  top: 350px;
  position: relative;
}

.portfolio-video {
  left: -80px;
  top: -60px;
  position: relative;
}

.portfolio-text-content {
  display: flex;
  /* justify-content: center; */
  top: 500px;
  flex-direction: column;
  position: relative;
}

.portfolio-text-content h2 {
  /* color: #FFF;
  text-align: center;
  font-family: Oswald;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px; */
  color: #BC9B5D;
/* text-align: center; */
font-family: Inter;
font-size: 70px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 9px;
margin-left: 90px;
margin-top: 90px;
}

.portfolio-text-content p {

  color: #FFF;
  /* text-align: center; */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  text-align: left;
  max-width: 970px;

}

.same-row-cards {
  display: flex;
  justify-content: space-around;
}

.card-text h4 {
  color: #BC9B5D;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
  text-transform: uppercase;
}

.card-text hr {
  border: 4px solid #BC9B5D;
  width: 379.047px;
}

.text-content h4,
h6 {
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1px;
}

img.card-image {
  width: 100%;
  height: auto;
  /* min-height: 370px; */
  /* max-height: 370px; */
}

/* .image-list-container-web{
  height: 50vh;
} */

a.gallery-page-text{
  color: #FFF;
text-align: center;
font-family: Oswald;
font-size: 130px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 9px;
}

.disabled {
  pointer-events: none; /* Disable pointer events */
  opacity: 0.5; /* Reduce opacity for a visual cue */
}


/* Responsivness styles */
/* @media only screen and (max-width: 600px) */
@media only screen and (max-width: 600px) {
  div.brand{
   left: 30px;
   position: relative;
  }
  header ul{
    margin-right: 0px;
    display: flex;
    justify-content: center;
  }
  
  div.brand h1{
    color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.6px; /* 76.667% */
  letter-spacing: 3.45px;
  top: 21px;
  position: relative;
  }
  span.brand{
    color: #BC9B5D;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 2.9px;
  top: 8px;
  position: relative;
  }
  .form-inline2{
    display: none;
  }

  .web-h {
    visibility: visible;
  }
  h4.text-white{
    top: 0px;
    position: relative;
  }
  .about-content .row {
    top: 44px;
    position: relative;
  }

  /* .mob-vi{
    height: 100vh;
    width: auto;
   } */
   .mob-vi{
    /* top: -4px; */
    top: -114px;
    position: relative;
   }
  .navbar-nav {
    top: 120px;
    position: relative;
  }

  .brand {
    width: 108px;
    height: 43.173px;
    flex-shrink: 0;
  }

  .navbar-collapse {
    background: transparent;
    height: 100vh;
    font-size: 40px;
    width: 100vw;
  }
  .show{
    background: #1B1B19;
  }

  .custom-navbar {
    padding-left: 0;
    padding-right: 0;
  }

  a.nav-link {
    font-size: 24px;
    text-transform: uppercase;
    line-height: 80px;
    color: white;
  }

  .navbar-brand {
    left: -88px;
    position: relative;
  }

  .test {
    margin: 0;
    margin-bottom: 40px;
    top: 50px;
    position: relative;
  }

  .banner-text {
    position: absolute;
    /* bottom: 100px;
    right: 70px; */
    bottom: 33px;
    right: 19px;
    padding: 20px;
  }
  .box-content {
    top: 0px;
    position: relative;
  }
  .banner-text h2 {
    font-size: 63px;
  }

  .banner-text h4 {
    font-size: 23px;
    top: -20px;
    left: 68px;
    position: relative;
  }

  .player {
    width: 300px;
    height: 544px;
  }

  /* .footer-content{
    display: flex;
    justify-content: space-between;
    top: -2px;
    position: relative;
  } */
  .banner-text h5 {
    font-size: 25.262px;
    top: -30px;
    position: relative;
  }

  section.about-page {
    /* background-color: red; */
    /* height: 492px; */
    /* height: 492px; */
    /* height: 611px; */
    height: 515px;
  }

  .portfolio-page {
    background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
    margin-top: 0px;
    height: 2554px;
    top: 0px;
    position: relative;
  }

  .about-content {
    top: 4px;
    position: relative;
    margin: 0;
  }
  
  .imdb-icon {
    width: 45px;
    height: 18.633px;
    flex-shrink: 0;
    /* bottom: 1px; */
    top: 10px;
    position: relative;
  }

  .about-page h1 {
    color: #BC9B5D;
    /* text-align: center; */
    font-family: Inter;
    /* font-size: 64px; */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px;
    display: flex;
    justify-content: flex-start;
    top: 0px;
position: relative;
  }

  .footer-mob {
    display: flex;
    justify-content: space-between;
  }

  .main-image-container{
    min-width: 300px;
  }

  .footer-mob h4 {
    color: #1B1B19;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */
    letter-spacing: 0.75px;

  }

  .footer-mob h6,
  a {
    color: #1B1B19;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.75px;
  }
  .instagram-icon{
    top: -2px;
    position: relative;
  }
  img.instagram-icon {
    bottom: 1px;
    position: relative;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  .website-link a {
    text-decoration: none;
    color: #1B1B19;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.75px;
    top: 10px;
    position: relative;
  }

  .play-cover {
    width: 37px;
    height: 30px;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.70);
    top: 0px;
    position: relative;
  }

  .play-cover3 {
    width: 37px;
    height: 30px;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.70);
    top: 0px;
    position: relative;
  }

  .play-cover2 {
    width: 37px;
    height: 30px;
    flex-shrink: 0;
    /* background:rgba(0, 0, 0, 0.70); */
    top: 39px;
    left: 25px;
    position: relative;
  }

  .play-cover2 svg {
    width: 50px;
    height: 40px;
  }

  .play-btn {
    width: 20px;
    height: 15px;
    top: 5px;
    position: relative;
  }

  .fa-pause {
    top: 5px;
    position: relative;
    color: white;
  }

  .about-page p {
    color: #000;
    /* text-align: center; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    height: auto;
    top: 22px;
    position: relative;
    line-height: 27px;
  }

  .about-video {
    top: 80px;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }

  .player2 {
    height: 100vh;
    width: 100%;
  }

  .portfolio-content h1 {
    color: #BC9B5D;
    text-align: center;
    font-family: Oswald;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px;
    top: 10px;
    position: relative;

  }

  .footer {
    height: 156px;
    margin-bottom: 0;
  }
  #footer{
    top: -100px;
    position: relative;
  }
  .svg-signature {
    top: 83px;
    position: relative;
    width: 48.551px;
    height: 39.376px;
    flex-shrink: 0;
  }

  .same-row-card {
    top: 40px;
    position: relative;
  }

  .title-container {
    top: -200px;
    position: relative;
  }

  .title-container h1 {
    top: 210px;
    position: relative;
  }

  .port-v {
    top: -220px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .player {
    width: 100px;
    width: 323px;
  }

  /* .portfolio-content{
    height: auto;
  } */
  .gallery-banner h1 {
    font-size: 80px;
  }

  .title {
    font-size: 60px;
  }

  /* .galleryyyy{
    background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
    background-color: #0c0a0a;
    background: #0c0a0a;
    
  } */
  .gallery-page{
    background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%);
    max-height: 758px;
  }
  .gallery-page,
  .gallery-container {
    /* background: radial-gradient(50% 50% at 50% 50%, #1A1A18 75.5%, #191917 91%, #161614 100%); */
    height: 100%;
    border-bottom: 0px;
    top: 0px;
    position: relative;
    /* max-height: 758px; */
  }

  .image-list-container-mob {
    visibility: visible;
    top: 0px;
    position: relative;
    width: 100%;
    height: auto;
    /* left: -30px; */
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .pagination-container {
    top: -200px;
    position: relative;
  }

  .image-list-container-web {
    visibility: hidden;
  }

  .pagination-container {
    display: flex;
    flex-direction: row;
  }

  .pagination-container .nav-arrow {
    color: #FFF;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    top: 1px;
    position: relative;
    /* 756% */
    letter-spacing: 0.1px;
    gap: 10px;
  }
  .controls{
    top: -20px;
    position: relative;
  }
  .video-control{
    top: -85px;
    position: relative;
  }
  .pagination-count {
    top: 110px;
    position: relative;
    color: #FFF;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 756% */
    letter-spacing: 0.1px;
    gap: 20px;
  }

  .page-number.active {
    color: #BC9B5D; /* Add any styles you want for the active page */
  }

  .actor-name {
    display: flex;
    justify-content: center;
    top: -3px;
    left: -15px;
    position: relative;
  }
  /* .-name.vedio-lists {
    display: flex;
    justify-content: center;
    top: -3px;
    left: -15px;
    position: relative;
  } */
  .actor-name2 {
    display: flex;
    justify-content: space-around;
    top: 120px;
    left: -50px;
    position: relative;
  }

  .actor-name h2 {
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    left: -50px;
    /* left: -50px; */
    position: relative;
    text-transform: none;
  }

  .actor-name h4 {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    top: -50px;
    left: 18px;
    position: relative;
  }

  .actor-name h5 {
    color: #BC9B5D;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    left: -40px;
    top: -110px;
    position: relative;
  }

  .actor-name2 h2 {
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    /* left: -50px; */
    position: relative;
  }

  .actor-name2 h4 {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    top: -50px;
    left: 100px;
    position: relative;
  }

  .actor-name2 h5 {
    color: #BC9B5D;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 236.25% */
    letter-spacing: 9.45px;
    left: 30px;
    top: -110px;
    position: relative;
  }

  .expand {
    top: 26px;
    left: 59px;
    position: relative;
  }

  /* Add these styles to your existing CSS file */

  .main-image-container.lightbox-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .main-image-container.lightbox-open img {
    /* max-width: 90%;
    max-height: 90%; */
    width: auto;
    height: 650px;
    max-height: 650px;
  }

  .expand.hidden {
    display: none;
  }

  .close-lightbox {
    position: absolute;
    top: 150px;
    right: 10px;
    color: white;
    cursor: pointer;
  }

  .portfolio-content {
    top: 20px;
    position: relative;
  }

  .signature {
    width: 148.551px;
    height: 39.376px;
    flex-shrink: 0;
    /* top: -80px; */
    top: -40px;
    position: relative;
    /* display: none; */
  }

  .portfolio-video {
    display: flex;
    justify-content: center;
    top: 15px;
    left: 3px;
    position: relative;
    /* background-image: url('/port_vedio1.mp4'); */
  }

  .portfolio-content h1 {
    /* top: 25px; */
    top: 84px;
    position: relative;
    /* color: #BC9B5D;
    text-align: center;
    font-family: Oswald;
    font-size: 85px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px; */
    color: #BC9B5D;
text-align: center;
font-family: Inter;
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: 60px; /* 125% */
letter-spacing: 5px;
  }

  .portfolio-text-content {
    /* top: 170px; */
    top: 80px;
    position: relative;
  }

  .portfolio-text-content h2 {
    color: #BC9B5D;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 2px;
    margin-left: 0;
    margin-top: 0px;
  }

  .portfolio-text-content p {
    color: #FFF;
    /* text-align: center; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    line-height: 20px;
    top: 20px;
    position: relative;
  }

  .text-for-mobile-screen {
    top: 80px;
    position: relative;
  }

  .each-text {
    line-height: 60px;
  }

  .text-for-mobile-screen h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 9px;
  }

  .line {
    top: -10px;
    position: relative;
  }

  .same-row-cards {
    top: -100px;
    position: relative;
  }

  /* .same-row-card img{
    min-width: 100%;
    width: 100%;
   
  } */
  .portfolio-page {
    /* min-height: 4206px; */
    /* min-height: 3513px;Very important */
    /* min-height: 2966px; */
    min-height: 3155px;
    height: auto;
    top: 127px;
    position: relative;
  }

  .same-row-card h4.card-title-2 {
    color: #BC9B5D;
    /* text-align: center; */
    text-align: left;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px;
  }

  .same-row-card h4.card-title-1 {
    color: #BC9B5D;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 9px;
  }

  .card-text hr {
    border: 8px solid #BC9B5D;

    width: 232px;
    height: 0px;
    flex-shrink: 0;
  }

  .player {
    width: 300px;
  }

  .videos {
    width: 300px;

    &:nth-child(2) {
      top: 20px;
      margin-top: 20px;
      position: relative;

    }
  }

  .text-content h4 {
    color: #FFF;
    /* text-align: center; */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
  }

  .title {
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 75.6px;
    /* 315% */
    letter-spacing: 9.45px;
    top: 23px;
    position: relative;
  }

  .text-content h6 {
    color: #FFF;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1px;
  }

  .col-12 {
    /* Styles for all elements with the class name 'same-row-card' */

    /* Styles for the second div with the class name 'same-row-card' */
    &:nth-child(2) {
      top: 40px;
      margin-top: 40px;
      position: relative;
    }

    /* Styles for the third div with the class name 'same-row-card' */
    &:nth-child(3) {
      top: 40px;
      margin-top: 50px;
      position: relative;

    }
  }

  .gallery-banner h1 {
    font-size: 40px;
  }

  /* YourComponent.css */

  .video-container { 
    position: relative;
    width: 100%;
    overflow: hidden;
    height: auto;

  }

  .video-container video {
    object-fit: cover;
    height: 90%;

  }
  .video-gallery-text{
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 75.6px; /* 756% */
letter-spacing: 0.1px;
top: -24px;
position: relative;
  }
  .video-list{
    display: flex;
    justify-content: space-around;
  }

}

@media screen and (max-width: 480px) {
  .portfolio-page {
    /* height: 392vh;Very important */
    height: 293vh;
    top: 180px;
    position: relative;
    /* or set a different height for Samsung phones */
  }
  header ul{
    margin-right: 0px;
    display: flex;
    justify-content: center;
  }
}

/* @media only screen and (max-width: 1185) {
  .same-row-card h4 {
    font-size: 20px;
  }
} */

/* @media (min-width: 1069px) and (max-width: 1024px) {
 
  .same-row-card h4 {
    font-size: 14px; 
  }

  
} */

/* New media query for tablets */ 
/* 1265px 1275 */
@media only screen and (min-width: 601px) and (max-width: 1275px) {
  .form-inline2{
    display: none;
  }
  header ul{
    margin-right: 0px;
    display: flex;
    justify-content: center;
  }
  /* Styles for tablets */
  .banner-text {
    /* bottom: 80px; */
    bottom: 20px;
    /* Adjust the bottom spacing for tablets */
    right: 50px;
    /* Adjust the right spacing for tablets */
    padding: 15px;
    /* Adjust padding for tablets */
  }
  .first_svg{
    width: 120px;
    left: 21px;
    top: 5px;
    position: relative;
  }
  .second_svg{
    width: 120px;
  }
  .banner-text h2 {
    font-size: 50px;
    /* Adjust font size for tablets */
  }

  .banner-text h4 {
    font-size: 20px;
    /* Adjust font size for tablets */
    top: -15px;
    /* Adjust top spacing for tablets */
    left: 50px;
    /* Adjust left spacing for tablets */
  }

  .banner-text h5 {
    font-size: 18px;
    /* Adjust font size for tablets */
    top: -20px;
    /* Adjust top spacing for tablets */
  }

  /* .about-page h1 {
    color: #000;
    text-align: center;
    font-family: Oswald;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px;
  } */

  .about-page h1 {
    color: #BC9B5D;
    /* text-align: center; */
    font-family: Inter;
    /* font-size: 64px; */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 9px;
    display: flex;
    justify-content: flex-start;
    top: 40px;
/* position: relative;
    display: flex; */
    justify-content: center;
  }

  .about-page p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    top: 15px;
    position: relative;
  }

  .card-text h4 {
    font-size: 25px;
  }
  .portfolio-content h4.text-white{
    top: -10px;
    position: relative;
  }
  .portfolio-content h1 {
    font-size: 40px;
  }

  .signature {
    width: 100px;
    height: 40px;
    top: -17px;
    position: relative;
  }
  .portfolio-text-content h2 {
    color: #BC9B5D;
    /* text-align: center; */
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 9px;
    margin-left: 7px;
    margin-top: 90px;
}
  /* .portfolio-text-content h2 {
    font-size: 70px;
  } */

  .portfolio-text-content p {
    font-size: 20px;
    font-family: Inter;
  }

  .about-content p {
    font-size: 20px;
    text-align: left;
  }

  .same-row-card h4 {
    font-size: 20px;
  }

  .same-row-card h6 {
    font-size: 15px;
  }

  .image-list-container {
    display: flex;
    overflow-x: scroll;
    margin-top: 20px;
    /* align-content: center; */
  }

  .gallery-banner h1 {
    font-size: 70px;
  }

  .image-list-tab {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    left: 40px;
    height: auto;
    position: relative;
  }

  

  .play-pause-btn {
    position: absolute;
    top: 83%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
  }

  .play-pause-btn2 {
    position: absolute;
    top: 24%;
    left: 32%;
    transform: translate(-50%, -50%);
    border: none;
    background: transparent;
  }

  .play-cover2 svg {
    width: 100px;
    height: 40px;
  }

  .play-cover {
    width: 57px;
    height: 35px;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.70);
  }

  .play-btn {

    top: 5px;
    position: relative;
  }

  .fa-pause {
    top: 5px;
    position: relative;
    color: white;
  }
  .card-title-1{
    font-size: 24px;
  }
  .portfolio-page {
    /* min-height: 4206px; */
    /* min-height: 2200px; */
    min-height: 2300px;
    height: auto;
  }
  .footer-link{
    top: 27px;
    position: relative;
  }
  /* .portfolio-text-content h2{
    font-size: 50px;
  } */
  .navbar-collapse{
    background: transparent;
  }
  .show{
    background: #1B1B19;
  }
  a.gallery-page-text{
    color: #FFF;
  text-align: center;
  font-family: Oswald;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 766px) {
  .portfolio-page{
    background-color: rebeccapurple;
    height: 4100px;
  }
  .mob-vi{
    top: -117px;
    position: relative;
  }
  .about-page{
    top: -131px;
    position: relative;
  }
}

@media only screen and (min-width: 767px) and (max-width: 782px) {
  .portfolio-page{
    background-color: rebeccapurple;
    height: 2100px;
  }

}

@media only screen and (min-width: 1276px) and (max-width: 1600px) {
 .banner-text {
  position: absolute;
    bottom: 72px;
    right: 11px;
    padding: 20px;
    color: #FFF;
 }
}

@media only screen and (min-width: 1275px) and (max-width: 1465px) {
  .about-page p{
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1275px) {
  .portfolio-page {
   
   
    height: 2400px;
   
  }
}

@media only screen and (min-width: 1276px) and (max-width: 1850px) {
  .portfolio-page {
   
   
    height: 2700px;
   
  }
}

/* Media query for large screens */
@media only screen and (min-width: 1851px) and (max-width: 2251px) {
  .portfolio-page {
   
   
    height: 2800px;
   
  }
}

/* @media only screen and (min-width: 2252px) and (max-width: 3000px) { */
  @media screen and (min-width: 2052px) {
  .portfolio-page {
   
   
    height: 3000px;
   
  }
}

/* height: 3200px; */