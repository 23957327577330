/* Gallery.css */
.image-gallery-box{
    display: flex;
    justify-content: space-around;
  }
  .image-gallery-box {
    position: relative;
    top: -20px;
  }
  
  .navarrow1 {
    position: absolute;
    left: 30%; /* Adjust as needed */
    top: 40%;
  }
  
  .navarrow2 {
    position: absolute;
    right: 33%; /* Adjust as needed */
    top: 40%;
  }

  @media (max-width: 1027px) {
    .navarrow1 {
      position: absolute;
      left: 30%; /* Adjust as needed */
      top: 40%;
    }
    
    .navarrow2 {
      position: absolute;
      right: 33%; /* Adjust as needed */
      top: 40%;
    }
  }
  
  
  .gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-image-container {
    margin-top: 20px;
  }
  
  .main-image-container img {
    width: 330px;
  height: 491px;
  flex-shrink: 0;
  /* left: 17px;
  position: relative; */
    /* max-width: 100%; */
    /* max-height: 400px; */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  .main-image-container.lightbox-open img{
    width: auto;
    height: 720px;
  }
  
  
  .image-list-container {
    display: flex;
    overflow-x: scroll;
    margin-top: 20px;
  }
  
  /* .image-list-container img {
    width: 158px;
  height: 237px;
  flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;
  
    border-radius: 5px;
  } */

  .image-grid {
    display: flex;
    overflow-x: auto;
    gap: 10px;
   
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .image-grid img {
    width: 158px;
    height: 237px;
    flex-shrink: 0;
  }
 
  .image-list-container img.selected {
    border-color: #3498db; /* Highlight the selected image */
  }
  @media (max-width: 768px) {
    .image-list-container {
      width: 100%;
      overflow-x: auto; /* Add horizontal scroll for smaller screens */
    }
  
    .image-list-container img {
      width: 80px;
      height: 120px;
    }
    .nav-arrow-icons{
      font-size: 20px;
      height: 40px;
      width: 40px;
    }
    .navarrow1{
      left: -5px;
      position: relative;
    }
    .navarrow2{
      left: 5px;
      position: relative;
    }
    .nav-arrow {
      font-size: 24px; /* Adjust the font size as needed */
      margin-top: 50%; /* Move the arrows down by 50% of the parent container's height */
      cursor: pointer;
      
    }
    /* .gallery-container{
      margin: 10px;
    } */
    .main-image-container.lightbox-open img{
      width: auto;
      height: 500px;
    }
    .main-image-container{
      display: flex;
      justify-content: center;
    }
    .main-image-container img{
      /* width: 188px; */
      width: auto;
      height: 281px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-arrow-icons{
      width: 26px;
      height: 26px;
      top: -56px;
      position: relative;
    }
    .navarrow1{
      left: 50px;
      position: relative;
    }
    .navarrow2{
      left: -50px;
      position: relative;
    }
    .video-control2{
      top: -83px;
      /* top: -14px; */
      position: relative;
    }
    .expand-small-text{
      color: #FFF;
text-align: center;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 75.6px; /* 756% */
letter-spacing: 0.1px;
left: 58px;
position: relative;
    }
    .image-gallery-box {
      position: relative;
      top: 0px;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .image-list-container-web,.image-list-container-mob {
      display: flex;
      overflow-x: scroll;
      margin-top: 20px;
    }
    .image-list-container-mob33{
      top: -50px;
      position: relative;
    }
  }